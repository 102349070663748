import { PlatformServicesAPI, Experiments, PlatformEnvData, SessionServiceAPI } from '@wix/thunderbolt-symbols'
import { biFactory } from './bi'
import { createFedopsLoggerFactory } from './fedopsLogger'
import { platformAppBiLoggerFactory } from './biLoggerFactory'
import { monitoringFactory } from './monitoring'
import { reportTraceFactory } from './reportTrace'
import { BsiManager } from '../../bsiManagerModule'
import type { AppEssentials } from '@wix/fe-essentials-viewer-platform'

type createPlatformAppServicesApiParams = {
	documentData: PlatformEnvData['document']
	biData: PlatformEnvData['bi']
	appDefinitionId: string
	instanceId: string
	experiments: Experiments
	csrfToken: string
	bsiManager: BsiManager
	sessionService: SessionServiceAPI
	appEssentials: AppEssentials
}

export const createPlatformAppServicesApi = ({
	documentData,
	biData,
	appDefinitionId,
	instanceId,
	experiments,
	csrfToken,
	bsiManager,
	sessionService,
	appEssentials,
}: createPlatformAppServicesApiParams): PlatformServicesAPI => {
	const viewMode = biData.isPreview ? ('preview' as const) : ('site' as const)

	const bi = biFactory({ biData, viewMode, sessionService })
	const biLoggerFactory = platformAppBiLoggerFactory.createBiLoggerFactoryForApp({
		appDefinitionId,
		instanceId,
		biData,
		bsiManager,
		viewMode,
		sessionService,
		factory: appEssentials.biLoggerFactory,
	})
	const reportTrace = reportTraceFactory({ biData, experiments, appDefinitionId, sessionService, appEssentials })
	const fedOpsLoggerFactory = createFedopsLoggerFactory({ biData, viewMode, sessionService, appEssentials })
	const monitoring = monitoringFactory({ url: biData.pageData.pageUrl, viewMode, viewerVersion: biData.viewerVersion, referrer: documentData.referrer })

	return {
		getCsrfToken: () => csrfToken,
		bi,
		biLoggerFactory,
		reportTrace,
		fedOpsLoggerFactory,
		monitoring,
		essentials: appEssentials,
	}
}
