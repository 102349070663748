import { PlatformEnvData, SessionServiceAPI } from '@wix/thunderbolt-symbols'

export const biFactory = ({ biData, viewMode, sessionService }: { biData: PlatformEnvData['bi']; viewMode: 'site' | 'preview'; sessionService: SessionServiceAPI }) => {
	const {
		msId: metaSiteId,
		viewerSessionId,
		visitorId,
		requestId,
		initialTimestamp,
		initialRequestTimestamp,
		isCached,
		is_rollout,
		dc,
		isjp,
		btype,
		viewerVersion,
		pageData: { isLightbox, pageUrl, pageId, pageNumber },
		muteBi,
		ownerId,
		isMobileFriendly,
		isPreview,
		svSession,
	} = biData

	const siteMemberId = sessionService.getSiteMemberId()

	return {
		// wixBiSession data
		viewerSessionId,
		visitorId,
		requestId,
		siteMemberId,
		isCached,
		is_rollout,
		dc,
		isjp,
		btype,
		pageLoadStart: initialTimestamp,
		networkPageLoadStart: initialRequestTimestamp,
		pageNumber,
		// rendererModel data
		metaSiteId,
		ownerId,
		isMobileFriendly,
		viewMode,
		isPreview,
		svSession,
		// viewer data
		pageId,
		pageUrl,
		isServerSide: !process.env.browser,
		viewerName: 'thunderbolt',
		artifactVersion: `thunderbolt-${viewerVersion}`,
		isPopup: isLightbox,
		// query params data
		muteBi,
	}
}
