import { PlatformLogger } from '@wix/thunderbolt-symbols'

export default (logger: PlatformLogger) => (url: string, scriptName: string, appIdentifier?: { appDefinitionId: string; controllerType?: string }) => {
	const importScriptsWithRetry = () => {
		try {
			self.importScripts(url)
		} catch {
			self.importScripts(url)
		}
	}

	return appIdentifier
		? logger.withReportingAndErrorHandlingSync('script_loaded', importScriptsWithRetry, appIdentifier)
		: logger.runAndReport(`import_scripts_${scriptName}`, importScriptsWithRetry)
}
